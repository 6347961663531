var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated)?_c('v-layout',{attrs:{"row":"","wrap":""}},[(_vm.error)?[_c('h2',[_vm._v(_vm._s(_vm.error))])]:_vm._e(),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"128"}}),_c('h1',[_vm._v("Please wait...")])],1)]),_c('v-flex',{attrs:{"xs12":"","sm12":"","lg12":""}},[_c('v-card',[_c('v-card-title',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date Range","readonly":"","outlined":"","hide-details":"","prepend-inner-icon":_vm.$vuetify.icons.values.mdiCalendar},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}],null,false,128574064),model:{value:(_vm.menuDateRange),callback:function ($$v) {_vm.menuDateRange=$$v},expression:"menuDateRange"}},[_c('v-date-picker',{attrs:{"range":"","scrollable":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateRange = [];
                _vm.menuDateRange = false;
                _vm.update();}}},[_vm._v("Cancel / Clear")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuDateRange = false;
                _vm.update();}}},[_vm._v("OK")])],1)],1),_c('v-spacer'),_c('v-text-field',{staticClass:"horizontal-list-container",attrs:{"label":"Search","single-line":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.doSearch()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{on:{"click":function($event){return _vm.doSearch()}}},[_vm._v(_vm._s(_vm.$vuetify.icons.values.mdiMagnify))])]},proxy:true}],null,false,2144628036),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","footer-props":{
            'items-per-page-options': [10, 25, 50, 100, -1],
          },"items-per-page":25,"loading":_vm.loading,"headers":_vm.logbookHeaders,"items":_vm.filteredLogbook},scopedSlots:_vm._u([{key:`item.date`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:`item.reference`,fn:function({ item }){return [_c('router-link',{attrs:{"to":{ name: 'park', params: { id: item.reference } }}},[_vm._v(_vm._s(item.reference)+" "+_vm._s(item.name)+" "+_vm._s(item.parktypeDesc))])]}},{key:`item.view`,fn:function({ item }){return [_c('v-btn',{attrs:{"outlined":"","x-small":"","to":{
                name: 'user_logbook',
                params: {
                  userId: _vm.userId,
                  activationReference: item.reference,
                  activationDate: item.date,
                },
              }}},[_vm._v("View")])]}}],null,true)}),_c('small',[_c('p')])],1)],1)],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
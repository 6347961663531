<template>
  <v-layout row wrap v-if="isAuthenticated">
    <template v-if="error">
      <h2>{{ error }}</h2>
    </template>
    <v-overlay :value="overlay">
      <div class="text-center">
        <v-progress-circular indeterminate size="128"></v-progress-circular>
        <h1>Please wait...</h1>
      </div>
    </v-overlay>
    <v-flex xs12 sm12 lg12>
      <v-card>
        <v-card-title>
          <v-menu
            v-model="menuDateRange"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                readonly
                outlined
                hide-details
                :prepend-inner-icon="$vuetify.icons.values.mdiCalendar"
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker range scrollable v-model="dateRange">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="
                  dateRange = [];
                  menuDateRange = false;
                  update();
                "
                >Cancel / Clear</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="
                  menuDateRange = false;
                  update();
                "
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            outlined
            hide-details
            @keyup.enter="doSearch()"
            class="horizontal-list-container"
          >
            <template v-slot:append>
              <v-icon @click="doSearch()">{{
                $vuetify.icons.values.mdiMagnify
              }}</v-icon>
            </template>
          </v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            dense
            :footer-props="{
              'items-per-page-options': [10, 25, 50, 100, -1],
            }"
            :items-per-page="25"
            :loading="loading"
            :headers="logbookHeaders"
            :items="filteredLogbook"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ item.date | date }}
            </template>
            <template v-slot:[`item.reference`]="{ item }">
              <router-link
                :to="{ name: 'park', params: { id: item.reference } }"
                >{{ item.reference }} {{ item.name }}
                {{ item.parktypeDesc }}</router-link
              >
            </template>
            <template v-slot:[`item.view`]="{ item }">
              <v-btn
                outlined
                x-small
                :to="{
                  name: 'user_logbook',
                  params: {
                    userId: userId,
                    activationReference: item.reference,
                    activationDate: item.date,
                  },
                }"
                >View</v-btn
              >
            </template>
          </v-data-table>
          <small>
            <p></p>
          </small>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.horizontal-list {
  list-style-type: none;
  overflow: hidden;
}
.horizontal-list li.heading {
  font-weight: bold;
}
.horizontal-list li {
  float: left;
  margin-right: 0;
}
.horizontal-list-container {
  margin: 12px 0 12px -12px;
}
</style>

<script>
import axios from "axios";
import dayjs from "dayjs";

export default {
  beforeMount() {},
  mounted() {
    this.load();
    this.initialize();
  },
  beforeCreate() {},
  created() {
    this.initialize();
  },
  beforeUpdate() {},
  updated() {},
  methods: {
    initialize() {
      if (this.activationReference) this.search = this.activationReference;
    },
    doSearch() {
      this.update();
    },
    load() {
      if (this.userId && this.userId == this.id) return;

      if (this.id == this.$store.state.user.userId) return;

      if (this.userId) this.id = this.userId;
      else this.id = this.$store.state.user.userId;

      this.loading = true;

      this.error = null;
      this.logbook = [];

      var params = "";

      if (
        this.userId != null &&
        this.userId != this.$store.state.user.userId &&
        this.isUserSupport
      )
        params += `&userId=${this.userId}`;

      params += "&all=1";

      if (params.length > 0) params = params.replace("&", "?"); // only replaces first instance

      axios
        .get(
          `https://${process.env.VUE_APP_API_URL}/user/activations${params}`,
          this.$store.getters.authTokenHeader
        )
        .then((response) => response.data)
        .then((json) => {
          this.count = json.count;
          this.logbook = json.activations;
          this.loading = false;
          this.update();
        })
        .catch((err) => {
          this.error = err;
        });
    },
    update() {
      var items = this.logbook;

      if (this.dateRange != null && this.dateRange.length > 0) {
        var dates = this.dateRange.sort(); // ensure earliest date is first; picker doesn't care
        var startDate = dayjs(dates[0]);
        var endDate = startDate;
        if (dates.length > 1) endDate = dayjs(dates[1]);
        endDate = endDate.add(1, "day");

        items = items.filter(
          (x) => dayjs(x.date) >= startDate && dayjs(x.date) < endDate
        );
      }

      if (this.search && this.search != "") {
        var regex = new RegExp(this.search, "i");
        items = items.filter(
          (x) =>
            regex.test(x.reference) ||
            regex.test(x.name) ||
            regex.test(x.locationDesc)
        );
      }

      this.filteredLogbook = items;
    },
  },
  data() {
    return {
      id: -1,
      error: null,
      loading: false,
      overlay: false,
      search: "",
      dateRange: [],
      menuDateRange: false,
      count: 0,
      logbook: [],
      filteredLogbook: [],
      logbookHeaders: [
        { value: "date", text: "Date", align: "left" },
        { value: "callsign", text: "Callsign", align: "left" },
        { value: "reference", text: "Park", align: "left" },
        { value: "locationDesc", text: "Location", align: "center" },
        { value: "cw", text: "CW", align: "center" },
        { value: "data", text: "Data", align: "center" },
        { value: "phone", text: "Phone", align: "center" },
        { value: "total", text: "Total", align: "center" },
        { value: "view", text: "", align: "right" },
      ],
    };
  },
  watch: {},
  props: ["userId", "activationReference"],
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated;
      },
    },
    isUserSupport: {
      get() {
        return this.$store.state.user.isUserSupport;
      },
    },
    dateRangeText() {
      if (this.dateRange == null || this.dateRange == "") return null;
      return this.dateRange.join(" ~ ");
    },
  },
  components: {},
};
</script>

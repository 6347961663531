<template>
  <v-dialog v-model="display" max-width="1024px">
    <template #activator="{ on, attrs }">
      <slot name="activator" :attrs="attrs" :on="on">
        <v-btn v-bind="attrs" v-on="on"> Support </v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title class="headline green white--text" primary-title>
        <span>{{ title }}</span>
      </v-card-title>
      <v-card-text>
        <br />

        <v-form ref="form" v-model="valid" lazy-validation :readonly="sending">
          <v-text-field
            v-if="userCallsign"
            v-model="userCallsign"
            readonly
            outlined
            label="Callsign"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="callsign"
            outlined
            :rules="callsignRules"
            required
            label="Callsign or Name"
          ></v-text-field>

          <v-text-field
            v-if="userEmail"
            v-model="userEmail"
            readonly
            outlined
            label="Email Address"
          ></v-text-field>
          <v-text-field
            v-else
            v-model="email"
            outlined
            required
            :rules="emailRules"
            label="Email Address"
          ></v-text-field>

          <v-text-field
            v-if="customTopic"
            v-model="customTopic"
            readonly
            outlined
            label="Topic"
          ></v-text-field>
          <v-select
            v-else
            v-model="topic"
            :items="topics"
            outlined
            label="Topic"
          ></v-select>

          <v-textarea
            v-model="publicText"
            outlined
            :rules="messageRules"
            required
            label="Message for Support"
          ></v-textarea>

          <v-card-subtitle v-if="notes">{{ notes }}</v-card-subtitle>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="display = false" color="error" :disabled="sending"
          >Cancel</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="send"
          color="primary"
          :disabled="sending"
          :loading="sending"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    title: {
      default: "Contact Support",
    },
    topic: {
      default: "Activating",
    },
    customTopic: null,
    publicMessage: {
      default: "",
    },
    privateMessage: null,
    tags: null,
    notes: null,
  },
  computed: {
    userCallsign: {
      get() {
        return this.$store.state.user.callsign;
      },
    },
    userEmail: {
      get() {
        return this.$store.state.user.email;
      },
    },
  },
  data() {
    return {
      display: false,
      sending: false,
      valid: false,
      topics: [
        "Activating",
        "Hunting",
        "Awards & Events",
        "Log Uploading",
        "Parks & Maps",
        "User Accounts",
        "Other",
      ],
      callsign: null,
      email: null,
      publicText: null,

      callsignRules: [(v) => !!v || "Callsign or name is required"],
      emailRules: [
        (v) => !!v || "Example: user@domain.com",
        (v) => /^\S+@\S+\.\S+$/.test(v) || "Please enter a valid email address",
      ],
      messageRules: [(v) => !!v || "A message to support is required"],
    };
  },
  mounted() {
    this.callsign = this.userCallsign;
    this.email = this.userEmail;
    this.publicText = this.publicMessage;
  },
  methods: {
    send() {
      if (!this.$refs.form.validate()) return;

      var data = new FormData();

      if (this.userCallsign) data.append("callsign", this.userCallsign);
      else data.append("callsign", this.callsign);

      if (this.userEmail) data.append("email", this.userEmail);
      else data.append("email", this.email);

      if (this.customTopic)
        data.append(
          "topic",
          "POTA.app - Support Request - " + this.customTopic
        );
      else data.append("topic", "POTA.app - Support Request - " + this.topic);

      if (this.publicText) data.append("public", this.publicText);

      if (this.privateMessage) data.append("private", this.privateMessage);

      if (this.tags) data.append("tags", this.tags.join(","));

      this.sending = true;

      axios
        .post(`https://${process.env.VUE_APP_API_URL}/support`, data)
        // eslint-disable-next-line
          .then((response) => {
          this.display = false;
          this.sending = false;
          this.$dialog.info({
            title: "Success",
            text: "Message has been sent to support. Please allow a few days for a response.",
          });
        })
        .catch((err) => {
          this.display = false;
          this.sending = false;
          this.$dialog.error({ title: "Error", text: `${err}` });
        });
    },
  },
};
</script>

<template>
  <v-layout row wrap v-if="isAuthenticated">
    <template v-if="error">
      <h2>{{ error }}</h2>
    </template>
    <v-flex xs12 sm12 lg12>
      <v-card flat>
        <v-card-title>
          Activated Parks
          <v-spacer></v-spacer>
          <v-btn
            outlined
            x-small
            @click="exportActivatorCSV()"
            v-if="activatorPark"
            >Export CSV</v-btn
          >
        </v-card-title>
        <v-card-text>
          <span v-if="!activatorPark">Loading...</span>
          <v-expansion-panels
            multiple
            focusable
            v-else-if="activatorPark.length > 0"
          >
            <v-expansion-panel
              v-for="entity in activatorParkEntities"
              :key="'activator_park_' + entity"
            >
              <v-expansion-panel-header>
                <h3>{{ entity }}</h3>
                <v-spacer></v-spacer>
                {{ getStatsForActivatorEntity(entity) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels multiple focusable flat tile>
                  <v-expansion-panel
                    v-for="location in getLocationsForActivatorEntity(entity)"
                    :key="'activator_park_location_' + entity + '_' + location"
                  >
                    <v-expansion-panel-header>
                      <b>{{ location }}</b>
                      <v-spacer></v-spacer>
                      {{ getStatsForActivatorEntityLocation(entity, location) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="activatorParkHeaders"
                        :items="
                          getStatsForActivatorEntityLocationParks(
                            entity,
                            location
                          )
                        "
                        disable-filtering
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.reference`]="{ item }">
                          <router-link
                            :to="{
                              name: 'park',
                              params: { id: item.reference },
                            }"
                            >{{ item.reference }} {{ item.park }}</router-link
                          >
                        </template>
                        <template v-slot:[`item.first`]="{ item }">
                          {{ item.first | date }}
                        </template>
                        <template v-slot:[`item.view`]="{ item }">
                          <v-btn
                            outlined
                            x-small
                            :to="{
                              name: 'user_activations',
                              params: {
                                userId: userId,
                                activationReference: item.reference,
                              },
                            }"
                            >View</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <span v-else>No Activations</span>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg12>
      <v-card flat>
        <v-card-title> Activated Bands </v-card-title>
        <v-card-text>
          <span v-if="!activatorBand">Loading...</span>
          <v-simple-table dense v-else-if="activatorBand.bands.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="n in activatorBand.bands.length"
                    :key="n + '_a_band'"
                  >
                    {{ activatorBand.bands[n - 1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>CW</th>
                  <td
                    v-for="n in activatorBand.bands.length"
                    :key="n + '_a_cw'"
                  >
                    {{ activatorBand.cw[n - 1] }}
                  </td>
                </tr>
                <tr>
                  <th>DATA</th>
                  <td
                    v-for="n in activatorBand.bands.length"
                    :key="n + '_a_data'"
                  >
                    {{ activatorBand.data[n - 1] }}
                  </td>
                </tr>
                <tr>
                  <th>PHONE</th>
                  <td
                    v-for="n in activatorBand.bands.length"
                    :key="n + '_a_phone'"
                  >
                    {{ activatorBand.phone[n - 1] }}
                  </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td
                    v-for="n in activatorBand.bands.length"
                    :key="n + '_a_total'"
                  >
                    {{ activatorBand.total[n - 1] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span v-else>No Activation QSOs</span>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg12>
      <v-card flat>
        <v-card-title>
          Hunted Parks
          <v-spacer></v-spacer>
          <v-btn outlined x-small @click="exportHunterCSV()" v-if="hunterPark"
            >Export CSV</v-btn
          >
        </v-card-title>
        <v-card-text>
          <span v-if="!hunterPark">Loading...</span>
          <v-expansion-panels
            multiple
            focusable
            v-else-if="hunterPark.length > 0"
          >
            <v-expansion-panel
              v-for="entity in hunterParkEntities"
              :key="'hunter_park_' + entity"
            >
              <v-expansion-panel-header>
                <h3>{{ entity }}</h3>
                <v-spacer></v-spacer>
                {{ getStatsForHunterEntity(entity) }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels multiple focusable flat tile>
                  <v-expansion-panel
                    v-for="location in getLocationsForHunterEntity(entity)"
                    :key="'hunter_park_location_' + entity + '_' + location"
                  >
                    <v-expansion-panel-header>
                      <b>{{ location }}</b>
                      <v-spacer></v-spacer>
                      {{ getStatsForHunterEntityLocation(entity, location) }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :headers="hunterParkHeaders"
                        :items="
                          getStatsForHunterEntityLocationParks(entity, location)
                        "
                        disable-filtering
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:[`item.reference`]="{ item }">
                          <router-link
                            :to="{
                              name: 'park',
                              params: { id: item.reference },
                            }"
                            >{{ item.reference }} {{ item.park }}</router-link
                          >
                        </template>
                        <template v-slot:[`item.first`]="{ item }">
                          {{ item.first | date }}
                        </template>
                        <template v-slot:[`item.view`]="{ item }">
                          <v-btn
                            outlined
                            x-small
                            :to="{
                              name: 'user_logbook',
                              params: {
                                userId: userId,
                                hunterReference: item.reference,
                              },
                            }"
                            >View</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <span v-else>No Hunted Parks</span>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg12>
      <v-card flat>
        <v-card-title> Hunted Bands </v-card-title>
        <v-card-text>
          <span v-if="!hunterBand">Loading...</span>
          <v-simple-table dense v-else-if="hunterBand.bands.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th></th>
                  <th v-for="n in hunterBand.bands.length" :key="n + '_h_band'">
                    {{ hunterBand.bands[n - 1] }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>CW</th>
                  <td v-for="n in hunterBand.bands.length" :key="n + '_h_cw'">
                    {{ hunterBand.cw[n - 1] }}
                  </td>
                </tr>
                <tr>
                  <th>DATA</th>
                  <td v-for="n in hunterBand.bands.length" :key="n + '_h_data'">
                    {{ hunterBand.data[n - 1] }}
                  </td>
                </tr>
                <tr>
                  <th>PHONE</th>
                  <td
                    v-for="n in hunterBand.bands.length"
                    :key="n + '_h_phone'"
                  >
                    {{ hunterBand.phone[n - 1] }}
                  </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td
                    v-for="n in hunterBand.bands.length"
                    :key="n + '_h_total'"
                  >
                    {{ hunterBand.total[n - 1] }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <span v-else>No Hunder QSOs</span>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<style scoped>
.horizontal-list {
  list-style-type: none;
  overflow: hidden;
}
.horizontal-list li.heading {
  font-weight: bold;
}
.horizontal-list li {
  float: left;
  margin-right: 0;
}
.horizontal-list-container {
  margin: 12px 0 12px -12px;
}
</style>

<script>
import axios from "axios";
import dayjs from "dayjs";

export default {
  beforeMount() {},
  mounted() {
    this.load();
  },
  beforeCreate() {},
  created() {},
  beforeUpdate() {},
  updated() {},
  methods: {
    load() {
      if (this.userId && this.userId == this.id) return;

      if (this.id == this.$store.state.user.userId) return;

      if (this.userId) this.id = this.userId;
      else this.id = this.$store.state.user.userId;

      this.loading = true;

      this.error = null;

      var params = "";

      if (
        this.userId != null &&
        this.userId != this.$store.state.user.userId &&
        this.isUserSupport
      )
        params += `&userId=${this.userId}`;

      if (params.length > 0) params = params.replace("&", "?"); // only replaces first instance

      var requestActivatorBand = axios.get(
        `https://${process.env.VUE_APP_API_URL}/user/stats/activator/band${params}`,
        this.$store.getters.authTokenHeader
      );
      var requestActivatorPark = axios.get(
        `https://${process.env.VUE_APP_API_URL}/user/stats/activator/park${params}`,
        this.$store.getters.authTokenHeader
      );
      var requestHunterBand = axios.get(
        `https://${process.env.VUE_APP_API_URL}/user/stats/hunter/band${params}`,
        this.$store.getters.authTokenHeader
      );
      var requestHunterPark = axios.get(
        `https://${process.env.VUE_APP_API_URL}/user/stats/hunter/park${params}`,
        this.$store.getters.authTokenHeader
      );

      requestActivatorBand
        .then((response) => response.data)
        .then((json) => {
          this.activatorBand = this.pivotBandCounts(json);
        })
        .catch((err) => {
          console.log(err);
        });

      requestActivatorPark
        .then((response) => response.data)
        .then((json) => {
          this.activatorPark = json;
        })
        .catch((err) => {
          console.log(err);
        });

      requestHunterBand
        .then((response) => response.data)
        .then((json) => {
          this.hunterBand = this.pivotBandCounts(json);
        })
        .catch((err) => {
          console.log(err);
        });

      requestHunterPark
        .then((response) => response.data)
        .then((json) => {
          this.hunterPark = json;
        })
        .catch((err) => {
          console.log(err);
        });

      this.loading = false;
    },
    pivotBandCounts(json) {
      var stats = {
        bands: [],
        cw: [],
        data: [],
        phone: [],
        total: [],
      };

      for (var i = 0; i < this.bands.length; i++) {
        var band = this.bands[i].toUpperCase();

        var rows = null;
        if (band == "80/75M")
          rows = json.filter(
            (x) => x.band == "80M" || x.band == "75M" || x.band == band
          );
        else rows = json.filter((x) => x.band == band);

        stats.bands.push(band.toLowerCase());
        stats.total.push(rows.reduce((prev, x) => prev + x.count, 0));
        stats.cw.push(
          rows
            .filter((x) => x.mode == "CW")
            .reduce((prev, x) => prev + x.count, 0)
        );
        stats.data.push(
          rows
            .filter((x) => x.mode == "DATA")
            .reduce((prev, x) => prev + x.count, 0)
        );
        stats.phone.push(
          rows
            .filter((x) => x.mode == "PHONE")
            .reduce((prev, x) => prev + x.count, 0)
        );
      }

      var total_all = json.reduce((prev, x) => prev + x.count, 0);
      var total_cw = json
        .filter((x) => x.mode == "CW")
        .reduce((prev, x) => prev + x.count, 0);
      var total_data = json
        .filter((x) => x.mode == "DATA")
        .reduce((prev, x) => prev + x.count, 0);
      var total_phone = json
        .filter((x) => x.mode == "PHONE")
        .reduce((prev, x) => prev + x.count, 0);

      var other_all = total_all - stats.total.reduce((prev, x) => prev + x, 0);
      var other_cw = total_cw - stats.cw.reduce((prev, x) => prev + x, 0);
      var other_data = total_data - stats.data.reduce((prev, x) => prev + x, 0);
      var other_phone =
        total_phone - stats.phone.reduce((prev, x) => prev + x, 0);

      stats.bands.push("Other");
      stats.total.push(other_all);
      stats.cw.push(other_cw);
      stats.data.push(other_data);
      stats.phone.push(other_phone);

      stats.bands.push("All Bands");
      stats.total.push(total_all);
      stats.cw.push(total_cw);
      stats.data.push(total_data);
      stats.phone.push(total_phone);

      return stats;
    },
    getStatsForActivatorEntity(entity) {
      var parks = this.activatorPark.filter((x) => x.entity == entity).length;
      var attempts = this.activatorPark
        .filter((x) => x.entity == entity)
        .map((x) => x.attempts)
        .reduce((total, value) => total + value);
      var activations = this.activatorPark
        .filter((x) => x.entity == entity)
        .map((x) => x.activations)
        .reduce((total, value) => total + value);
      var qsos = this.activatorPark
        .filter((x) => x.entity == entity)
        .map((x) => x.qsos)
        .reduce((total, value) => total + value);
      return `${activations} activations (${attempts} attempts) at ${parks} unique parks (${qsos} QSOs)`;
    },
    getLocationsForActivatorEntity(entity) {
      if (!this.activatorPark) return [];
      return this.activatorPark
        .filter((x) => x.entity == entity)
        .map((x) => x.location)
        .filter((v, i, a) => a.indexOf(v) == i);
    },
    getStatsForActivatorEntityLocation(entity, location) {
      var parks = this.activatorPark.filter(
        (x) => x.entity == entity && x.location == location
      ).length;
      var attempts = this.activatorPark
        .filter((x) => x.entity == entity && x.location == location)
        .map((x) => x.attempts)
        .reduce((total, value) => total + value);
      var activations = this.activatorPark
        .filter((x) => x.entity == entity && x.location == location)
        .map((x) => x.activations)
        .reduce((total, value) => total + value);
      var qsos = this.activatorPark
        .filter((x) => x.entity == entity && x.location == location)
        .map((x) => x.qsos)
        .reduce((total, value) => total + value);
      return `${activations} activations (${attempts} attempts) at ${parks} unique parks (${qsos} QSOs)`;
    },
    getStatsForActivatorEntityLocationParks(entity, location) {
      return this.activatorPark.filter(
        (x) => x.entity == entity && x.location == location
      );
    },
    getStatsForHunterEntity(entity) {
      var parks = this.hunterPark.filter((x) => x.entity == entity).length;
      var qsos = this.hunterPark
        .filter((x) => x.entity == entity)
        .map((x) => x.qsos)
        .reduce((total, value) => total + value);
      return `${qsos} QSOs with ${parks} unique parks`;
    },
    getLocationsForHunterEntity(entity) {
      if (!this.hunterPark) return [];
      return this.hunterPark
        .filter((x) => x.entity == entity)
        .map((x) => x.location)
        .filter((v, i, a) => a.indexOf(v) == i);
    },
    getStatsForHunterEntityLocation(entity, location) {
      var parks = this.hunterPark.filter(
        (x) => x.entity == entity && x.location == location
      ).length;
      var qsos = this.hunterPark
        .filter((x) => x.entity == entity && x.location == location)
        .map((x) => x.qsos)
        .reduce((total, value) => total + value);
      return `${qsos} QSOs with ${parks} unique parks`;
    },
    getStatsForHunterEntityLocationParks(entity, location) {
      return this.hunterPark.filter(
        (x) => x.entity == entity && x.location == location
      );
    },
    exportActivatorCSV() {
      var parks = this.activatorPark;
      if (!parks) parks = [];

      var csv =
        '"DX Entity","Location","HASC","Reference","Park Name","First QSO Date","Attempts","Activations","QSOs"\n' +
        parks
          .map(
            (x) =>
              `"${x.entity}","${x.location}","${x.short}","${x.reference}","${
                x.park
              }","${dayjs(x.first).format("YYYY-MM-DD")}",${x.attempts},${
                x.activations
              },${x.qsos}`
          )
          .join("\n");

      this.downloadFile(
        csv,
        "activator_parks.csv",
        "data:text/csv;charset=utf-8"
      );
    },
    exportHunterCSV() {
      var parks = this.hunterPark;
      if (!parks) parks = [];

      var csv =
        '"DX Entity","Location","HASC","Reference","Park Name","First QSO Date","QSOs"\n' +
        parks
          .map(
            (x) =>
              `"${x.entity}","${x.location}","${x.short}","${x.reference}","${
                x.park
              }","${dayjs(x.first).format("YYYY-MM-DD")}",${x.qsos}`
          )
          .join("\n");

      this.downloadFile(csv, "hunter_parks.csv", "data:text/csv;charset=utf-8");
    },
    downloadFile(content, fileName, mimeType) {
      var a = document.createElement("a");
      mimeType = mimeType || "application/octet-stream";

      if (navigator.msSaveBlob) {
        // IE10
        navigator.msSaveBlob(
          new Blob([content], {
            type: mimeType,
          }),
          fileName
        );
      } else if (URL && "download" in a) {
        //html5 A[download]
        a.href = URL.createObjectURL(
          new Blob([content], {
            type: mimeType,
          })
        );
        a.setAttribute("download", fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      } else {
        location.href =
          "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
      }
    },
  },
  data() {
    return {
      id: -1,
      error: null,
      loading: false,
      activatorBand: null,
      activatorPark: null,
      hunterBand: null,
      hunterPark: null,
      activatorParkHeaders: [
        { value: "reference", text: "Park", align: "left" },
        { value: "first", text: "First Activation", align: "left" },
        { value: "attempts", text: "Attempts", align: "center" },
        { value: "activations", text: "Activations", align: "center" },
        { value: "qsos_cw", text: "CW", align: "center" },
        { value: "qsos_data", text: "Data", align: "center" },
        { value: "qsos_phone", text: "Phone", align: "center" },
        { value: "qsos", text: "Total QSOs", align: "center" },
        { value: "view", text: "", align: "right" },
      ],
      hunterParkHeaders: [
        { value: "reference", text: "Park", align: "left" },
        { value: "first", text: "First Hunted", align: "left" },
        { value: "qsos", text: "QSOs", align: "center" },
        { value: "view", text: "", align: "right" },
      ],
    };
  },
  watch: {},
  props: ["userId"],
  computed: {
    isAuthenticated: {
      get() {
        return this.$store.state.user.isAuthenticated;
      },
    },
    isUserSupport: {
      get() {
        return this.$store.state.user.isUserSupport;
      },
    },
    activatorParkEntities: {
      get() {
        if (!this.activatorPark) return [];
        return this.activatorPark
          .map((x) => x.entity)
          .filter((v, i, a) => a.indexOf(v) == i);
      },
    },
    hunterParkEntities: {
      get() {
        if (!this.hunterPark) return [];
        return this.hunterPark
          .map((x) => x.entity)
          .filter((v, i, a) => a.indexOf(v) == i);
      },
    },
    bands: {
      get() {
        return this.$store.state.adif.potaBands;
      },
    },
  },
  components: {},
};
</script>

<template>
  <v-layout row wrap v-if="isAuthenticated">
    <template v-if="error">
      <h2>{{ error }}</h2>
    </template>
    <v-overlay :value="overlay">
      <div class="text-center">
      <v-progress-circular indeterminate size="128"></v-progress-circular>
      <h1>Please wait...</h1>
      </div>
    </v-overlay>
    <v-flex xs12 sm12 lg12>
      <v-card>
        <v-card-title>
          Earned Awards
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label="Search"
            single-line
            hide-details>
            <template v-slot:prepend>
              <v-icon>{{$vuetify.icons.values.mdiMagnify}}</v-icon>
            </template>
          </v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table :loading="needLoad" :headers="earnedHeaders" :items="earned" :items-per-page="10" :search="search">
            <template v-slot:[`item.dateGranted`]="{ item }">
              {{ item.dateGranted | dateTime }}
            </template>
            <template v-slot:[`item.radarDate`]="{ item }">
              {{ item.radarDate | date }}
            </template>
            <template v-slot:[`item.awardName`]="{ item} ">
              <a class="download_pdf" v-if="!Array.isArray(item.endorsements)" @click="downloadCertificate(item, null)">
                {{ item.awardName }}
              </a>
              <template v-else>{{ item.awardName }}</template>
            </template>
            <template v-slot:[`item.endorsements`]="{ item }">
              <template v-if="!Array.isArray(item.endorsements)">{{ item.endorsements }}</template>
              <template v-else v-for='(endorsement, index) in item.endorsements'>
                <span :key='`${item.awardName}.${endorsement}.${index}`'>
                  <span v-if="index > 0 && index < (item.endorsements.length)">, </span>
                  <a class="download_pdf" @click="downloadCertificate(item, endorsement)">{{ endorsement }}</a>
                </span>
              </template>
            </template>
          </v-data-table>
          <small>
            <p>Awards are updated daily and it may take a day or two for you to get full credit for uploaded logs. Hunters do not receive credit until the activator's log is processed.</p>
            <p>For awards that can be earned multiple times, 'Date First Granted' is the first date the award was earned, not when when upgrades, endorsements, etc. were earned.</p>            
          </small>
        </v-card-text>
      </v-card>
    </v-flex>

    <v-row style="margin: 5px 0 5px 0">

      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Parks Activated</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.activatorNumParks.numParks / this.activatorNumParks.nextAwardLevel) * 100"
              color="green"
              v-if="this.activatorNumParks">
              {{this.activatorNumParks.numParks}}
            </v-progress-circular>
            <v-progress-circular :size="200" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.activatorNumParks">Next award at {{this.activatorNumParks.nextAwardLevel}} unique parks.</v-card-subtitle>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Parks Hunted</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.hunterNumParks.numParks / this.hunterNumParks.nextAwardLevel) * 100"
              color="green"
              v-if="this.hunterNumParks">
              {{this.hunterNumParks.numParks}}
            </v-progress-circular>
            <v-progress-circular :size="200" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.hunterNumParks">Next award at {{this.hunterNumParks.nextAwardLevel}} unique parks.</v-card-subtitle>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Late Shift Activator Contacts</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.activatorLateShift.contacts / this.activatorLateShift.nextAwardLevel) * 100"
              color="green"
              v-if="this.activatorLateShift">
              {{this.activatorLateShift.contacts}}
            </v-progress-circular>
            <v-progress-circular :size="200" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.activatorLateShift">Next award at {{this.activatorLateShift.nextAwardLevel}} late shift QSOs.</v-card-subtitle>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Late Shift Hunter Contacts</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.hunterLateShift.contacts / this.hunterLateShift.nextAwardLevel) * 100"
              color="green"
              v-if="this.hunterLateShift">
              {{this.hunterLateShift.contacts}}
            </v-progress-circular>
            <v-progress-circular :size="250" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.hunterLateShift">Next award at {{this.hunterLateShift.nextAwardLevel}} late shift QSOs.</v-card-subtitle>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Early Shift Activator Contacts</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.activatorEarlyShift.contacts / this.activatorEarlyShift.nextAwardLevel) * 100"
              color="green"
              v-if="this.activatorEarlyShift">
              {{this.activatorEarlyShift.contacts}}
            </v-progress-circular>
            <v-progress-circular :size="200" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.activatorEarlyShift">Next award at {{this.activatorEarlyShift.nextAwardLevel}} early shift QSOs.</v-card-subtitle>
        </v-card>
      </v-flex>
      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Early Shift Hunter Contacts</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.hunterEarlyShift.contacts / this.hunterEarlyShift.nextAwardLevel) * 100"
              color="green"
              v-if="this.hunterEarlyShift">
              {{this.hunterEarlyShift.contacts}}
            </v-progress-circular>
            <v-progress-circular :size="200" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.hunterEarlyShift">Next award at {{this.hunterEarlyShift.nextAwardLevel}} early shift QSOs.</v-card-subtitle>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Entities Hunted</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.hunterDxEntity.completedPct)"
              color="green"
              v-if="this.hunterDxEntity">
              {{this.hunterDxEntity.numEntities}}
            </v-progress-circular>
            <v-progress-circular :size="200" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.hunterDxEntity">Next award at {{this.hunterDxEntity.nextAwardLevel}} unique entities.</v-card-subtitle>
        </v-card>
      </v-flex>

      <v-flex xs12 sm6 lg2>
        <v-card>
          <v-card-title>Park 2 Park</v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular
              :rotate="90" :size="200" :width="50"
              :value="(this.activatorP2P.contacts / this.activatorP2P.nextAwardLevel) * 100"
              color="green"
              v-if="this.activatorP2P">
              {{this.activatorP2P.contacts}}
            </v-progress-circular>
            <v-progress-circular :size="250" :width="50" indeterminate v-else></v-progress-circular>
          </v-card-text>
          <v-card-subtitle v-if="this.activatorP2P">Next award at {{this.activatorP2P.nextAwardLevel}} park-to-park QSOs.</v-card-subtitle>
        </v-card>
      </v-flex>

    </v-row>

    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Activated US States</v-card-title>
        <v-card-text>
          <ul class="state_list" v-if="this.activatorUSStates">
            <li v-for="state in Object.keys(usstates)" :key="'hunted_' + state" :class="activatorUSStates.includes(state) ? 'yes' : 'no'">{{ state }}</li>
          </ul>
          <div v-else>Not enough eligible activations.</div>
        </v-card-text>
        <v-card-subtitle>Activate a park in 50 states (DC is a wildcard) to earn this award.</v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Hunted US States</v-card-title>
        <v-card-text>
          <ul class="state_list" v-if="this.hunterUSStates">
            <li v-for="state in Object.keys(usstates)" :key="'hunted_' + state" :class="hunterUSStates.includes(state) ? 'yes' : 'no'">{{ state }}</li>
          </ul>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Hunt a park in 50 states (DC is a wildcard) to earn this award.</v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg4>
      <v-card>
        <v-card-title>Activated All References</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="allRefsHeaders" :items="activatorAllRefs" item-key="locationId" v-if="activatorAllRefs" :sort-by="['progress']" :sort-desc="[true]">
            <template v-slot:item.locationName="{ item }">{{ item.locationName }} ({{ item.locationDesc }})</template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.numParksActivated }} / {{ item.numParksAvailable }}</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible activations.</div>
        </v-card-text>
        <v-card-subtitle>Activate all of the parks in a state/province/region to earn this award.</v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 lg4>
      <v-card>
        <v-card-title>Hunted All References</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="allRefsHeaders" :items="hunterAllRefs" item-key="locationId" v-if="hunterAllRefs" :sort-by="['progress']" :sort-desc="[true]">
            <template v-slot:item.locationName="{ item }">{{ item.locationName }} ({{ item.locationDesc }})</template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.numParksActivated }} / {{ item.numParksAvailable }}</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Hunt all of the parks in a state/province/region to earn this award.</v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg4>
      <v-card>
        <v-card-title>Kilo</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="activatorKiloHeaders" :items="activatorKilo" item-key="reference" v-if="activatorKilo" :sort-by="['contacts']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} ({{item.reference}})</template>
            <template v-slot:item.contacts="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.contacts }}</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have 1000 total contacts from a single park as an activator.</v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Repeat Offender Activator</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="activatorRepeatHeaders" :items="activatorRepeat" item-key="reference" v-if="activatorRepeat" :sort-by="['activations']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} {{ item.parktypeDesc }} ({{item.reference}})</template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.activations % 20 }} / 20</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible activations.</div>
        </v-card-text>
        <v-card-subtitle>Activate a park 20 times to earn a repeat offender award. Higher level awards are earned every 20 activations.</v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Repeat Offender Hunter</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="hunterRepeatHeaders" :items="hunterRepeat" item-key="reference" v-if="hunterRepeat" :sort-by="['contacts']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} {{ item.parktypeDesc }} ({{item.reference}})</template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.contacts % 20 }} / 20</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Hunt a park 20 times to earn a repeat offender award. Higher level awards are earned every 20 contacts.</v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Activator Six Pack</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="activatorSixHeaders" :items="activatorSix" item-key="reference" v-if="activatorSix" :sort-by="['contacts']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} ({{item.reference}})</template>
            <template v-slot:item.contacts="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.contacts }}</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have 10 contacts at 6 different parks on 6M.</v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Hunter Six Pack</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="hunterSixHeaders" :items="hunterSix" item-key="reference" v-if="hunterSix" :sort-by="['contacts']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} ({{item.reference}})</template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have contacts with 6 different parks on 6M.</v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Activator N1CC</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="activatorN1CCHeaders" :items="activatorN1CC" item-key="reference" v-if="activatorN1CC" :sort-by="['bands']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} ({{item.reference}})</template>
            <template v-slot:item.bandlist="{ item }"><small>{{ item.bandlist }}</small></template>
            <template v-slot:item.bands="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.bands }}</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have contacts at 10 different parks on 10 different bands.</v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Hunter N1CC</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="hunterN1CCHeaders" :items="hunterN1CC" item-key="reference" v-if="hunterN1CC" :sort-by="['bands']" :sort-desc="[true]">
            <template v-slot:item.reference="{ item }">{{ item.name }} ({{item.reference}})</template>
            <template v-slot:item.bandlist="{ item }"><small>{{ item.bandlist }}</small></template>
            <template v-slot:item.bands="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.bands }}</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have contacts with 10 different parks on 10 different bands.</v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Activator Operator to Operator</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="activatorOp2OpHeaders" :items="activatorOp2Op" item-key="name" v-if="activatorOp2Op" :sort-by="['contacts']" :sort-desc="[true]">
            <template v-slot:item.name="{ item }">{{ item.name }}</template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.contacts % 50 }} / 50</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have at least 50 contacts with a hunter.</v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 lg6>
      <v-card>
        <v-card-title>Hunted Operator to Operator</v-card-title>
        <v-card-text>
          <v-data-table dense :headers="hunterOp2OpHeaders" :items="hunterOp2Op" item-key="name" v-if="hunterOp2Op" :sort-by="['contacts']" :sort-desc="[true]">
            <template v-slot:item.name="{ item }">{{ item.name }}</template>
            <template v-slot:item.progress="{ item }">
              <v-progress-linear :value="item.progress" height="25">
                <strong>{{ item.contacts % 50 }} / 50</strong>
              </v-progress-linear>
            </template>
          </v-data-table>
          <div v-else>Not enough eligible QSOs.</div>
        </v-card-text>
        <v-card-subtitle>Have at least 50 contacts with an activator.</v-card-subtitle>
      </v-card>
    </v-flex>

  </v-layout>
</template>

<style scoped>
.download_pdf {
  background: transparent url(/icon_pdf_16.gif) center right no-repeat;
  padding-right: 18px;
}
ul.state_list li {
  display: inline-block;
  width: 40px;
  line-height: 40px;
  text-align: center;
  margin: 0;
  border: 1px solid black !important;
}
ul.state_list li.yes {
  background-color: green;
}
ul.state_list li.no {
}
</style>

<script>
import axios from 'axios'

export default {
  beforeMount() {
  },
  mounted () {
  },
  beforeCreate() {
  },
  created() {
    this.updateAwards()
    this.updateProgress()
  },
  beforeUpdate() {
  },
  updated() {
    this.updateAwards()
    this.updateProgress()
  },
  methods: {
    updateAwards() {
      if (!this.needLoad && this.earned)
        return

      this.needLoad = false
      this.error = null
      this.earned = []

      var userId = ''
      if (this.userId != null && this.isUserSupport)
        userId = `?userId=${this.userId}`

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/user/award/earned${userId}`, this.$store.getters.authTokenHeader)
        .then(response => response.data)
        .then(json => {
          for (var i = 0; i < json.length; i++)
          {
            // for endorsement based awards, split the endorsements into an array
            // this is the trigger for the change in download link rendering
            if (this.endorsmentBasedAwards.includes(json[i].awardName))
            {
              if (json[i].endorsements)
              {
                json[i].endorsements = json[i].endorsements.split(',')
                for (var n = 0; n < json[i].endorsements.length; n++)
                {
                  json[i].endorsements[n] = json[i].endorsements[n].trim()
                }
              }
              else
                json[i].endorsements = []
            }
          }
          this.earned = json;
        })
        .catch(err => {
          console.log(err)
          this.error = err.response.data
        })
    },
    createDownloadURL(item, endorsement) {
      const award = JSON.parse(JSON.stringify(item));
      //console.log(award);

      var userId = this.$store.state.user.userId
      if (this.userId != null && this.isUserSupport)
        userId = this.userId

      var awardName = item.awardName.replaceAll(" ", "+")

      var badge = '0'
      if (award.badgeNum && award.badgeNum != '')
        badge = award.badgeNum

      var endorsements = endorsement
      if (!endorsements)
        endorsements = award.endorsements
      if (!endorsements)
        endorsements = '0'

      endorsements = endorsements.replaceAll(" ", "+")

      var details = '0'
      if (award.operator && award.operator != '')
        details = award.operator
      if (award.radarDate && award.radarDate != '')
        details = award.radarDate
      if (award.reference && award.reference != '')
        details = award.reference // repeat/kilo park reference

      details = details.replaceAll(" ", "+")

      var url = `https://${process.env.VUE_APP_API_URL}/award/advancedcertificate/${userId}/${awardName}/${badge}/${endorsements}/${details}`;

      //console.log(url);

      return url;
    },
    downloadCertificate(item, endorsement) {
      this.overlay = true

      var createUrl = this.createDownloadURL(item, endorsement)

      axios
        .get(createUrl, this.$store.getters.authTokenHeader)
        .then(response => response.data)
        .then(json => {
          this.overlay = false
          window.open(json.url, '_self')
        })
        .catch(err => {
          this.overlay = false
          this.error = err.response.data
        })
    },
    updateProgress() {
       if (!this.needLoadProgress)
        return

      this.needLoadProgress = false

      var userId = ''
      if (this.userId != null && this.isUserSupport)
        userId = `?userId=${this.userId}`

      var url_base = `https://${process.env.VUE_APP_API_URL}/user/award/progress`

      axios.all([
          axios.get(`${url_base}/activator/parks${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/parks${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/activator/lateshift${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/lateshift${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/activator/earlyshift${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/earlyshift${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/activator/p2p${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/dxentity${userId}`, this.$store.getters.authTokenHeader)
      ]).then(
          axios.spread((...responses) => {
            this.activatorNumParks = responses[0].data
            this.hunterNumParks = responses[1].data
            this.activatorLateShift = responses[2].data
            this.hunterLateShift = responses[3].data
            this.activatorEarlyShift = responses[4].data
            this.hunterEarlyShift = responses[5].data
            this.activatorP2P = responses[6].data
            this.activatorP2P.nextAwardLevel = ((this.activatorP2P.contacts / 25) | 0) * 25 + 25
            this.hunterDxEntity = responses[7].data
          })
      ).catch(err => {
        console.error(err)
      });

      axios.all([
          axios.get(`${url_base}/activator/usstates${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/usstates${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/activator/allrefs${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/allrefs${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/activator/repeatoffender${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/repeatoffender${userId}`, this.$store.getters.authTokenHeader)
      ]).then(
          axios.spread((...responses) => {
            this.activatorUSStates = responses[0].data
            if (this.activatorUSStates == null)
              this.activatorUSStates = []
            for (let i = 0; i < this.activatorUSStates.length; i++)
              this.activatorUSStates[i] = this.activatorUSStates[i].locationDesc.substring(3)
            this.hunterUSStates = responses[1].data
            if (this.hunterUSStates == null)
              this.hunterUSStates = []
            for (let i = 0; i < this.hunterUSStates.length; i++)
              this.hunterUSStates[i] = this.hunterUSStates[i].locationDesc.substring(3)

            this.activatorAllRefs = responses[2].data
            if (this.activatorAllRefs == null)
              this.activatorAllRefs = []
            for (let i = 0; i < this.activatorAllRefs.length; i++)
              this.activatorAllRefs[i].progress = this.activatorAllRefs[i].numParksActivated / this.activatorAllRefs[i].numParksAvailable * 100
            this.hunterAllRefs = responses[3].data
            if (this.hunterAllRefs == null)
              this.hunterAllRefs = []
            for (let i = 0; i < this.hunterAllRefs.length; i++)
              this.hunterAllRefs[i].progress = this.hunterAllRefs[i].numParksActivated / this.hunterAllRefs[i].numParksAvailable * 100

            this.activatorRepeat = responses[4].data
            if (this.activatorRepeat == null)
              this.activatorRepeat = []
            for (let i = 0; i < this.activatorRepeat.length; i++)
              this.activatorRepeat[i].progress = (this.activatorRepeat[i].activations % 20) / 20 * 100
            this.hunterRepeat = responses[5].data
            if (this.hunterRepeat == null)
              this.hunterRepeat = []
            for (let i = 0; i < this.hunterRepeat.length; i++)
              this.hunterRepeat[i].progress = (this.hunterRepeat[i].contacts % 20) / 20 * 100
          })
      ).catch(err => {
        console.error(err)
      });

      axios.all([
          axios.get(`${url_base}/activator/sixpack${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/hunter/sixpack${userId}`, this.$store.getters.authTokenHeader),
          axios.get(`${url_base}/activator/kilos${userId}`, this.$store.getters.authTokenHeader)
      ]).then(
          axios.spread((...responses) => {
            this.activatorSix = responses[0].data
            if (this.activatorSix == null)
              this.activatorSix = []
            for (let i = 0; i < this.activatorSix.length; i++)
            {
              this.activatorSix[i].progress = this.activatorSix[i].contacts / 10 * 100
              if (this.activatorSix[i].progress > 100)
                this.activatorSix[i].progress = 100
            }
            this.hunterSix = responses[1].data
            if (this.hunterSix == null)
              this.hunterSix = []

            this.activatorKilo = responses[2].data
            if (this.activatorKilo == null)
              this.activatorKilo = []
            for (let i = 0; i < this.activatorKilo.length; i++)
            {
              this.activatorKilo[i].progress = this.activatorKilo[i].contacts / 1000 * 100
              if (this.activatorKilo[i].progress > 100)
                this.activatorKilo[i].progress = 100
            }
          })
      ).catch(err => {
        console.error(err)
      });

      axios.all([
        axios.get(`${url_base}/activator/n1ccmultiband${userId}`, this.$store.getters.authTokenHeader),
        axios.get(`${url_base}/hunter/n1ccmultiband${userId}`, this.$store.getters.authTokenHeader)
      ]).then(
          axios.spread((...responses) => {
            function genBandList(bands) {
              bands = bands.toUpperCase().split(',')
              let avail = ['2180M','630M','560M','160M','80M','60M','40M','30M','20M','17M','15M',
                           '12M','10M','8M','6M','5M','4M','2M','1.25M','70CM','33CM','23CM','13M',
                           '9CM','6CM','3CM','1.25CM','6MM','4MM','2.5MM','2MM','1MM','SUBMM'];
              let done = [];
              avail.forEach((band) => {
                for (let i = 0; i < bands.length; i ++) {
                  if (bands[i] === band) {
                    done.push(band);
                    break;
                  }
                }
              });
              return done
            }
            this.activatorN1CC = responses[0].data
            if (this.activatorN1CC == null)
              this.activatorN1CC = []
            for (let i = 0; i < this.activatorN1CC.length; i++)
            {
              this.activatorN1CC[i].bandlist = genBandList(this.activatorN1CC[i].bandlist).join(',')
              this.activatorN1CC[i].progress = this.activatorN1CC[i].bands / 10 * 100
              if (this.activatorN1CC[i].progress > 100)
                this.activatorN1CC[i].progress = 100
            }

            this.hunterN1CC = responses[1].data
            if (this.hunterN1CC == null)
              this.hunterN1CC = []
            for (let i = 0; i < this.hunterN1CC.length; i++)
            {
              this.hunterN1CC[i].bandlist = genBandList(this.hunterN1CC[i].bandlist).join(',')
              this.hunterN1CC[i].progress = this.hunterN1CC[i].bands / 10 * 100
              if (this.hunterN1CC[i].progress > 100)
                this.hunterN1CC[i].progress = 100
            }
          })
      ).catch(err => {
        console.error(err)
      });

       axios.all([
        axios.get(`${url_base}/activator/op2op${userId}`, this.$store.getters.authTokenHeader),
        axios.get(`${url_base}/hunter/op2op${userId}`, this.$store.getters.authTokenHeader)
      ]).then(
          axios.spread((...responses) => {
            this.activatorOp2Op = responses[0].data
            if (this.activatorOp2Op == null)
              this.activatorOp2Op = []
            for (let i = 0; i < this.activatorOp2Op.length; i++)
            {
              this.activatorOp2Op[i].progress = (this.activatorOp2Op[i].contacts % 50) / 50 * 100
              if (this.activatorOp2Op[i].progress >= 100) {
                this.activatorOp2Op[i].progress = 100
              }
            }

            this.hunterOp2Op = responses[1].data
            if (this.hunterOp2Op == null)
              this.hunterOp2Op = []
            for (let i = 0; i < this.hunterOp2Op.length; i++)
            {
              this.hunterOp2Op[i].progress = (this.hunterOp2Op[i].contacts % 50) / 50 * 100
              if (this.hunterOp2Op[i].progress >= 100) {
                this.hunterOp2Op[i].progress = 100
              }
            }
          })
      ).catch(err => {
        console.error(err)
      });
    }
  },
  data () {
    return {
      needLoad: true,
      error: null,
      overlay: false,
      earned: [],
      earnedHeaders: [
          { value: 'dateGranted', text: 'Date First Granted', align: 'left' },
          { value: 'awardName', text: 'Award Name', align: 'left' },
          { value: 'awardDescription', text: 'Description', align: 'left' },
          { value: 'badgeNum', text: 'Badge', align: 'center' },
          { value: 'endorsements', text: 'Endorsements', align: 'center' },
          { value: 'reference', text: 'Repeat/Kilo Reference', align: 'center' },
          { value: 'parkName', text: 'Park Name', align: 'left' },
          { value: 'operator', text: 'Operator', align: 'center' },
          { value: 'radarDate', text: 'Rove Date', align: 'center' },
          { value: 'additionalDetails', text: 'Additional Details', align: 'left' }
        ],
      endorsmentBasedAwards: ["Support Your Parks Hunter","Support Your Parks Activator", "New Years Hunter", "New Years Activator"],

      usstates: {
        'AK': 'Alaska',
        'AL': 'Alabama',
        'AR': 'Arkansas',
        'AZ': 'Arizona',
        'CA': 'California',
        'CO': 'Colorado',
        'CT': 'Connecticut',
        'DC': 'District Of Columbia',
        'DE': 'Delaware',
        'FL': 'Florida',
        'GA': 'Georgia',
        'HI': 'Hawaii',
        'IA': 'Iowa',
        'ID': 'Idaho',
        'IL': 'Illinois',
        'IN': 'Indiana',
        'KS': 'Kansas',
        'KY': 'Kentucky',
        'LA': 'Louisiana',
        'MA': 'Massachusetts',
        'MD': 'Maryland',
        'ME': 'Maine',
        'MI': 'Michigan',
        'MN': 'Minnesota',
        'MO': 'Missouri',
        'MS': 'Mississippi',
        'MT': 'Montana',
        'NC': 'North Carolina',
        'ND': 'North Dakota',
        'NE': 'Nebraska',
        'NH': 'New Hampshire',
        'NJ': 'New Jersey',
        'NM': 'New Mexico',
        'NV': 'Nevada',
        'NY': 'New York',
        'OH': 'Ohio',
        'OK': 'Oklahoma',
        'OR': 'Oregon',
        'PA': 'Pennsylvania',
        'RI': 'Rhode Island',
        'SC': 'South Carolina',
        'SD': 'South Dakota',
        'TN': 'Tennessee',
        'TX': 'Texas',
        'UT': 'Utah',
        'VA': 'Virginia',
        'VT': 'Vermont',
        'WA': 'Washington',
        'WI': 'Wisconsin',
        'WV': 'West Virginia',
        'WY': 'Wyoming'
      },

      allRefsHeaders: [
        { text: 'Location', value: 'locationName' },
        { text: 'Progress', value: 'progress' }
      ],
      activatorRepeatHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Activations', value: 'activations' },
        { text: 'Next Award', value: 'progress'}
      ],
      hunterRepeatHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Contacts', value: 'contacts' },
        { text: 'Next Award', value: 'progress'}
      ],
      activatorSixHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Contacts', value: 'contacts' }
      ],
      hunterSixHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Contacts', value: 'contacts' }
      ],
      activatorN1CCHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Band List', value: 'bandlist'},
        { text: 'Bands', value: 'bands' }
      ],
      hunterN1CCHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Band List', value: 'bandlist'},
        { text: 'Bands', value: 'bands' }
      ],
      activatorKiloHeaders: [
        { text: 'Park', value: 'reference' },
        { text: 'Contacts', value: 'contacts' }
      ],
      hunterOp2OpHeaders: [
        { text: 'Callsign', value: 'name' },
        { text: 'Contacts', value: 'contacts' },
        { text: 'Next Award', value: 'progress'}
      ],
      activatorOp2OpHeaders: [
        { text: 'Callsign', value: 'name' },
        { text: 'Contacts', value: 'contacts' },
        { text: 'Next Award', value: 'progress'}
      ],

      search: null,

      needLoadProgress: true,
      activatorNumParks: null,
      hunterNumParks: null,
      activatorLateShift: null,
      hunterLateShift: null,
      activatorUSStates: null,
      hunterUSStates: null,
      activatorAllRefs: null,
      hunterAllRefs: null,
      activatorRepeat: null,
      hunterRepeat: null,
      activatorSix: null,
      hunterSix: null,
      activatorN1CC: null,
      hunterN1CC: null,
      activatorKilo: null,
      activatorP2P: null,
      activatorEarlyShift: null,
      hunterEarlyShift: null,
      hunterDxEntity: null,
      activatorOp2Op: null,
      hunterOp2Op: null
    }
  },
  props: [ 'userId' ],
  computed: {
    isAuthenticated: {
      get () {
        return this.$store.state.user.isAuthenticated
      }
    },
    isUserSupport: {
      get () {
        return this.$store.state.user.isUserSupport
      }
    },
  },
  components: {
  }
}
</script>
